import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Upload,
  message,
  Space,
} from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { myprofiledata } from "../../../components/Data/data";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import swal from "sweetalert";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { PROFILE, UPLOADS_URL, USER_AUTH } from "../../../config/constants/api";
import { Put } from "../../../config/api/put";
import moment from "moment";
import { addUser } from "../../../redux/slice/authSlice";
import { FaCamera } from "react-icons/fa";
import { Country, State, City } from "country-state-city";
import dayjs from "dayjs";

// const beforeUpload = (file) => {
//   const isJpgOrPng =
//     file.type === "image/jpeg" ||
//     file.type === "image/png" ||
//     file.type === "image/webp";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return isJpgOrPng && isLt2M;
// };
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};
const DropzoneFiltercards = () => {
  const [loading, setLoading] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [licensefrontUrl, setLicensefrontUrl] = useState("");
  const [licensefrontObject, setLicensefrontObject] = useState(null);
  const [licenseCardUrl, setLicenseCardUrl] = useState("");
  const [licenseCardObject, setLicenseCardObject] = useState(null);

  const [idCardFrontUrl, setIdCardFrontUrl] = useState("");
  const [idCardFrontObject, setIdCardFrontObject] = useState(null);
  const [idCardBackUrl, setIdCardBackUrl] = useState("");
  const [idCardBackObject, setIdCardBackObject] = useState(null);
  const [form] = Form.useForm();
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((state) => state.user.userData);
  // const profileDetails = useSelector((state) => state.user.profileDetails);
  const [countries, setCountries] = useState([]);
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateIso, setStateIso] = useState("");
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateName("");
    setStates(State.getStatesOfCountry(countryIso));
  }, []);
  useEffect(() => {
    setCityName("");
    setCitites(City.getCitiesOfState(countryIso, stateIso));
  }, []);
  useEffect(() => {
    const countryIsoCode =
      userData.country &&
      Country.getAllCountries().find(
        (country) => country.name === userData.country
      )?.isoCode;
    setCountryIso(countryIsoCode);
    setCountryName(userData.country);

    // Populate states based on the country
    if (countryIsoCode) {
      const states = State.getStatesOfCountry(countryIsoCode);
      setStates(states);

      const stateIsoCode =
        userData.state &&
        states.find((state) => state.name === userData.state)?.isoCode;
      setStateIso(stateIsoCode);
      setStateName(userData.state);

      // Populate cities based on the state
      if (stateIsoCode) {
        const cities = City.getCitiesOfState(countryIsoCode, stateIsoCode);
        setCitites(cities);

        const cityName =
          userData.city &&
          cities.find((city) => city.name === userData.city)?.name;
        setCityName(cityName);
      }
    }
    form.setFieldsValue({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      preferredName: userData?.preferredName,
      email: userData?.email,
      mobile: userData?.mobile,
      emergencyContact: userData?.emergencyContact,
      gender: userData?.gender,
      homeDZFBO: userData?.homeDZFBO,
      streetAddressOne: userData?.streetAddressOne,
      streetAddressTwo: userData?.streetAddressTwo,
      uspaNo: userData?.uspaNo,
      pilotLicense: userData?.pilotLicense,
      zipCode: userData?.zipCode,
      dateOfBirth: dayjs(userData?.dateOfBirth),
    });
    setImageUrl(UPLOADS_URL + userData?.image);
    setIdCardFrontUrl(UPLOADS_URL + userData?.govIdFront);
    setIdCardBackUrl(UPLOADS_URL + userData?.govIdBack);
    setLicensefrontUrl(UPLOADS_URL + userData?.licenseFront);
    setLicenseCardUrl(UPLOADS_URL + userData?.licenseCard);
  }, []);
  const dispatch = useDispatch();

  const handleChange = (fieldName, value, e) => {
    switch (fieldName) {
      case "City":
        setCityName(value);
        break;
      case "Country":
        const { name, isoCode } = JSON.parse(value);
        setCountryName(name);
        setCountryIso(isoCode);
        break;
      case "State":
        setStateName(value);
        setStateIso(e?.key);
        break;
      default:
        return;
    }
  };
  const navigate = useNavigate();
  const onFinish = (values) => {
    const {
      country,
      city,
      state,
      firstName,
      lastName,
      gender,
      preferredName,
      mobile,
      dateOfBirth,
      emergencyContact,
      homeDZFBO,
      streetAddressOne,
      streetAddressTwo,
      uspaNo,
      pilotLicense,
      zipCode,
    } = values;
    const formValuesChanged = () => {
      return (
        userData?.firstName !== firstName ||
        userData?.lastName !== lastName ||
        userData?.preferredName !== preferredName ||
        userData?.country !== country ||
        userData?.city !== city ||
        userData?.state !== state ||
        userData?.gender !== gender ||
        userData?.homeDZFBO !== homeDZFBO ||
        userData?.streetAddressOne !== streetAddressOne ||
        userData?.streetAddressTwo !== streetAddressTwo ||
        userData?.emergencyContact !== emergencyContact ||
        userData?.uspaNo !== uspaNo ||
        userData?.pilotLicense !== pilotLicense ||
        userData?.zipCode !== zipCode ||
        !dayjs(userData?.dateOfBirth).isSame(dateOfBirth) ||
        userData?.mobile !== mobile ||
        imageObject ||
        idCardFrontObject ||
        idCardBackObject ||
        licensefrontObject ||
        licenseCardObject
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if (imageObject) {
        data.append("image", imageObject);
      }
      if (idCardFrontObject) {
        data.append("govIdFront", idCardFrontObject);
      }
      if (idCardBackObject) {
        data.append("govIdBack", idCardBackObject);
      }
      if (licensefrontObject) {
        data.append("licenseFront", licensefrontObject);
      }
      if (licenseCardObject) {
        data.append("licenseCard", licenseCardObject);
      }
      data.append("dateOfBirth", moment(dateOfBirth?.$d).format("YYYY-MM-DD"));
      data.append("mobile", mobile);
      data.append("gender", gender);
      data.append("country",  countryName);
      data.append("city", cityName);
      data.append("state", stateName);
      data.append("streetAddressOne", streetAddressOne);
      data.append("streetAddressTwo", streetAddressTwo);
      data.append("homeDZFBO", homeDZFBO);
      data.append("uspaNo", uspaNo);
      data.append("pilotLicense", pilotLicense);
      data.append("zipCode", zipCode);
      data.append("emergencyContact", emergencyContact);
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      data.append("preferredName", preferredName);
      Put(USER_AUTH.updateAccountDetails, token, data, {}, "multipart")
        .then((response) => {
          if (response?.status) {
            form.resetFields();
            swal("Success", "Profile Updated Successfully", "success");
            dispatch(addUser({ user: response?.data, token: token }));
            navigate("/Profile");
          }
        })
        .catch((err) => {
          console.log("Error", err);
          swal("Error", err?.response?.data?.message, "error");
        });
    } else {
      swal("System Alert", "No Changes Detected", "error");
      return;
    }
  };
  // const handleChangepro = (info) => {
  //   if (info.file.status === "uploading") {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     getBase64(info.file.originFileObj, (url) => {
  //       setImageObject(info.file.originFileObj);
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  //   if (info.file.status === "error") {
  //     message.error("Upload failed. Please try again with a smaller file.");
  //     setLoading(false);
  //   }
  // };
  const handleChangePro = (info, name) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        if (name === "image") {
          setImageObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setImageUrl(url);
        } else if (name === "govIdFront") {
          setIdCardFrontObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setIdCardFrontUrl(url);
        } else if (name === "govIdBack") {
          setIdCardBackObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setIdCardBackUrl(url);
        } else if (name === "licenseFront") {
          setLicensefrontObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setLicensefrontUrl(url);
        } else if (name === "licenseCard") {
          setLicenseCardObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setLicenseCardUrl(url);
        }
        setLoading(false);
      }
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image
      </div>
    </button>
  );

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Edit Profile</h3>
                      </div>
                      <div className="bg-parent">
                        <Row
                          gutter={[16, 16]}
                          align={""}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={10} xl={8}>
                            <div className="wrapper-group-1000001858">
                              <>
                                <Upload
                                  name="image"
                                  showUploadList={false}
                                  style={{ position: "relative" }}
                                  onChange={(e) => handleChangePro(e, "image")}
                                  beforeUpload={beforeUpload}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      height: "300px",
                                      border: "1px solid gray",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {imageUrl ? (
                                      <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={UPLOADS_URL + userData?.image}
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                          maxHeight: "360px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          filter: "blur(1px)",
                                        }}
                                      />
                                    )}
                                    <FaCamera
                                      style={{
                                        position: "absolute",
                                        color: "rgb(0,127,202)",
                                        fontSize: "25px",
                                      }}
                                    />
                                  </div>{" "}
                                </Upload>
                              </>
                            </div>
                          </Col>

                          <Col md={14} lg={14} xl={16}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="edit-profile-info">
                                  <Form
                                    className="row g-3"
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                      remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                  >
                                    <Row
                                      style={{ width: "100%" }}
                                      gutter={[16, 16]}
                                    >
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="First Name"
                                          name="firstName"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Last Name"
                                          name="lastName"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Preferred Name"
                                          name="preferredName"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="Email" name="Email">
                                          <Input
                                            size="large"
                                            placeholder={userData?.email}
                                            className="web-input"
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Phone Number"
                                          name="mobile"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            type="number"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Emergency Contact"
                                          name="emergencyContact"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            type="number"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="Gender" name="gender">
                                          <Select placeholder="Select">
                                            <Select.Option value="MALE">
                                              Male
                                            </Select.Option>
                                            <Select.Option value="FEMALE">
                                              Female
                                            </Select.Option>
                                            <Select.Option value="OTHERS">
                                              Others
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Country"
                                          name="country"
                                        >
                                          <Space wrap className="filter-select">
                                            <Select
                                              size="large"
                                              // defaultValue="Select"
                                              value={countryName || "Select"}
                                              showSearch
                                              placeholder="Country"
                                              optionFilterProp="children"
                                              onChange={(val, e) => {
                                                handleChange("Country", val, e);
                                              }}
                                              filterOption={(input, option) =>
                                                option.value
                                                  .toLowerCase()
                                                  .includes(input.toLowerCase())
                                              }
                                              options={countries?.map(
                                                (country) => ({
                                                  value: JSON.stringify({
                                                    name: country.name,
                                                    isoCode: country.isoCode,
                                                  }),
                                                  label: country?.name,
                                                })
                                              )}
                                            />
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="city">
                                          <Space wrap className="filter-select">
                                            <Select
                                              // defaultValue="Select"
                                              onChange={(val, e) => {
                                                handleChange("City", val, e);
                                              }}
                                              value={cityName}
                                              style={{ width: "100%" }}
                                            >
                                              {cities.map((cityObj) => (
                                                <Select.Option
                                                  key={cityObj?.isoCode}
                                                  value={cityObj?.name}
                                                >
                                                  {cityObj?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="state">
                                          <Space wrap className="filter-select">
                                            <Select
                                              // defaultValue="Select"
                                              onChange={(val, e) => {
                                                handleChange("State", val, e);
                                              }}
                                              value={stateName}
                                              style={{ width: "100%" }}
                                            >
                                              {states.map((stateObj) => (
                                                <Select.Option
                                                  key={stateObj?.isoCode}
                                                  value={stateObj?.name}
                                                >
                                                  {stateObj?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Date Of Birth"
                                          name="dateOfBirth"
                                        >
                                          <DatePicker className="web-input" />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="HomeDZ/FBO"
                                          name="homeDZFBO"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Street Address 1"
                                          name="streetAddressOne"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Street Address 2"
                                          name="streetAddressTwo"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Skydiving USPA #"
                                          name="uspaNo"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Zip Code"
                                          name="zipCode"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Pilot License"
                                          name="pilotLicense"
                                        >
                                          <Input
                                            size="large"
                                            className="AuthFormInput"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}></Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Upload ID Card Pictures (front)"
                                          valuePropName="fileList"
                                          getValueFromEvent={normFile}
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <>
                                            <Upload
                                              name="idCardFront"
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              showUploadList={false}
                                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                              beforeUpload={beforeUpload}
                                              onChange={(e) => {
                                                handleChangePro(
                                                  e,
                                                  "govIdFront"
                                                );
                                              }}
                                            >
                                              {idCardFrontUrl ? (
                                                <img
                                                  src={idCardFrontUrl}
                                                  alt="avatar"
                                                  style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "280px",
                                                  }}
                                                />
                                              ) : (
                                                uploadButton
                                              )}
                                            </Upload>
                                          </>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Upload ID Card Pictures (backside)"
                                          valuePropName="fileList"
                                          getValueFromEvent={normFile}
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <>
                                            <Upload
                                              name="idCardBack"
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              showUploadList={false}
                                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                              beforeUpload={beforeUpload}
                                              onChange={(e) => {
                                                handleChangePro(e, "govIdBack");
                                              }}
                                            >
                                              {idCardBackUrl ? (
                                                <img
                                                  src={idCardBackUrl}
                                                  alt="avatar"
                                                  style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "280px",
                                                  }}
                                                />
                                              ) : (
                                                uploadButton
                                              )}
                                            </Upload>
                                          </>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Upload skydiving license front"
                                          valuePropName="fileList"
                                          getValueFromEvent={normFile}
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <>
                                            <Upload
                                              name="licensefront"
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              showUploadList={false}
                                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                              beforeUpload={beforeUpload}
                                              onChange={(e) => {
                                                handleChangePro(
                                                  e,
                                                  "licenseFront"
                                                );
                                              }}
                                            >
                                              {licensefrontUrl ? (
                                                <img
                                                  src={licensefrontUrl}
                                                  alt="avatar"
                                                  style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "280px",
                                                  }}
                                                />
                                              ) : (
                                                uploadButton
                                              )}
                                            </Upload>
                                          </>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Upload pilot License card"
                                          valuePropName="fileList"
                                          getValueFromEvent={normFile}
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <>
                                            <Upload
                                              name="pilotLicense"
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              showUploadList={false}
                                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                              beforeUpload={beforeUpload}
                                              onChange={(e) => {
                                                handleChangePro(
                                                  e,
                                                  "licenseCard"
                                                );
                                              }}
                                            >
                                              {licenseCardUrl ? (
                                                <img
                                                  src={licenseCardUrl}
                                                  alt="avatar"
                                                  style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "280px",
                                                  }}
                                                />
                                              ) : (
                                                uploadButton
                                              )}
                                            </Upload>
                                          </>
                                        </Form.Item>
                                      </Col>

                                      <div
                                        className=""
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          type=""
                                          htmlType="submit"
                                          className="btn web-btn px-5"
                                        >
                                          Update
                                        </Button>
                                      </div>
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
