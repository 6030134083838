import React, { useEffect, useState } from "react";
import {
  Layout,
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Image,
  Checkbox,
  Select,
  Space,
  Upload,
  DatePicker,
  Modal,
} from "antd";
import { useLocation, useNavigate } from "react-router";
import { Get } from "../../config/api/get";
import { USER_AUTH } from "../../config/constants/api";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { Put } from "../../config/api/put";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../redux/slice/authSlice";

const Presignup = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  },[]);

  const onFinish = (values) => {
    Get(USER_AUTH.uspaCheck, null, {
      LastName: values?.lastName,
      MemNum: values?.uspaNo,
    })
      .then((response) => {
        if (response?.status && response?.data?.MemNum !== "") {
          Put(USER_AUTH.verifyUSPA, null , {email}).then((response) => {
            setLoading(false);
            if (response?.status) {
              dispatch(addUser({ user: response.data }));
              swal("Success!", response?.message, "success");
              navigate("/");
            } 
          }).catch((err) => {
            let message = err?.response?.data?.message || err?.message;
            setLoading(false);
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          }
          );
        } else {
          setShowModal(true);
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) setShowModal(true);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleProceedWithoutUSPA = () => {
    navigate("/");
  };
  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner" style={{ padding: "30px 0" }}>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={14} lg={8}>
            <div className="auth-box">
              <div className="blur-bg-inner-card-form">
                <h2 className="auth-heading">USPA Verification</h2>
                <p className="auth-p">
                  Fill Out This Form To get USPA verified
                </p>
                <Form
                  className="row m-auto g-3 signup-new-form"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                    isAdmin: false,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Col lg={24} md={24} xs={24}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Enter Last Name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="web-input"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} xs={24}>
                    <Form.Item
                      label="Skydiving USPA Member #"
                      name="uspaNo"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Skydiving USPA #",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Skydiving USPA #"
                        className="web-input"
                      />
                    </Form.Item>
                  </Col>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      htmlType="submit"
                      className="btn web-btn px-5"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {loading ? "Loading..." : "Verify"}
                    </Button>
                    <Button
                      className="btn web-btn px-5"
                      onClick={handleProceedWithoutUSPA}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {loading ? "Loading..." : "Submit Without Verification"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        centered
        open={showModal}
        onCancel={() => setShowModal(false)}
        title="USPA Verification Failed"
        footer={null}
        // className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
      >
        <div className="p-4">
          <p>USPA verification failed. Please choose an option:</p>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="outline"
              onClick={() => setShowModal(false)}
              className="web-btn"
              style={{
                height: "40px",
              }}
            >
              Try Again
            </Button>
            <Button
              className="web-btn"
              style={{
                height: "40px",
              }}
              onClick={handleProceedWithoutUSPA}
            >
              Sign Up Without Verification
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Presignup;
