import React from "react";
import { useEffect, useRef, useState } from "react";
// import AuthLayout from "../../components/";
import {
  Layout,
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Image,
  Checkbox,
  Select,
  Space,
  Upload,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { USER_AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import fbicn from "../../assets/facebook-icon.png";
import gogoleicn from "../../assets/google-icon.png";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Country, State, City } from "country-state-city";
import axios from "axios";

import swal from "sweetalert";
import moment from "moment";
import { Get } from "../../config/api/get";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};

function CreateAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const [selfieUrl, setSelfieUrl] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [licensefrontUrl, setLicensefrontUrl] = useState("");
  const [licensefrontObject, setLicensefrontObject] = useState(null);
  const [licenseCardUrl, setLicenseCardUrl] = useState("");
  const [licenseCardObject, setLicenseCardObject] = useState(null);

  const [idCardFrontUrl, setIdCardFrontUrl] = useState("");
  const [idCardFrontObject, setIdCardFrontObject] = useState(null);
  const [idCardBackUrl, setIdCardBackUrl] = useState("");
  const [idCardBackObject, setIdCardBackObject] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateIso, setStateIso] = useState("");
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateName("");
    setStates(State.getStatesOfCountry(countryIso));
  }, [countryName]);
  useEffect(() => {
    setCityName("");
    setCitites(City.getCitiesOfState(countryIso, stateIso));
  }, [stateName]);
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  

  const onFinish = (values) => {
    setLoading(true);
    let data = new FormData();
    data.append("email", values?.email);
    data.append("password", values?.password);
    data.append("firstName", values?.firstName);
    data.append("lastName", values?.lastName);
    data.append("preferredName", values?.preferredName);
    data.append(
      "dateOfBirth",
      moment(values?.dateOfBirth?.$d).format("YYYY-MM-DD")
    );
    data.append("zipCode", values?.zipCode);
    data.append("gender", values?.gender);
    data.append("mobile", values?.mobile);
    data.append("emergencyContact", values?.emergencyContact);
    data.append("isAdmin", false);
    data.append("country", countryName);
    data.append("state", stateName);
    data.append("city", cityName);
    data.append("homeDZFBO", values?.homeDZFBO);
    data.append("streetAddressOne", values?.streetAddressOne);
    data.append("streetAddressTwo", values?.streetAddressTwo);
    data.append("pilotLicense", values?.pilotLicense);
    data.append("uspaNo", values?.uspaNo);
    data.append("image", imageObject);
    data.append("govIdFront", idCardFrontObject);
    data.append("govIdBack", idCardBackObject);
    data.append("licenseFront", licensefrontObject);
    data.append("licenseCard", licenseCardObject);
    Post(USER_AUTH.signup, data, null, null, "multipart")
    .then((response) => {
      setLoading(false);
      dispatch(
          addUser({ user: response.data?.user, token: response.data?.token })
        );
        swal("Success!", response?.message, "success");
        // navigate("/");
        navigate("/uspaVerification", {state : {email : values?.email}})
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (fieldName, value, e) => {
    switch (fieldName) {
      case "City":
        setCityName(value);
        break;
      case "Country":
        const { name, isoCode } = JSON.parse(value);
        setCountryName(name);
        setCountryIso(isoCode);
        break;
      case "State":
        setStateName(value);
        setStateIso(e?.key);
        break;
      default:
        return;
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image
      </div>
    </button>
  );

  // const handleChangepro = (info) => {
  //   setLoading(true);
  //   getBase64(
  //     info?.fileList[info?.fileList?.length - 1]?.originFileObj,
  //     (url) => {
  //       setImageObject(
  //         info?.fileList[info?.fileList?.length - 1]?.originFileObj
  //       );
  //       setLoading(false);
  //       setImageUrl(url);
  //     }
  //   );
  // };
  const handleChangePro = (info, name) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        if (name === "image") {
          setImageObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setImageUrl(url);
        } else if (name === "govIdFront") {
          setIdCardFrontObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setIdCardFrontUrl(url);
        } else if (name === "govIdBack") {
          setIdCardBackObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setIdCardBackUrl(url);
        } else if (name === "licenseFront") {
          setLicensefrontObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setLicensefrontUrl(url);
        } else if (name === "licenseCard") {
          setLicenseCardObject(
            info?.fileList[info?.fileList?.length - 1]?.originFileObj
          );
          setLicenseCardUrl(url);
        }
        setLoading(false);
      }
    );
  };
  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner" style={{ padding: "30px 0" }}>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={16}>
            <div className="auth-box">
              <div className="blur-bg-inner-card-form">
                <h2 className="auth-heading">Sign Up</h2>
                <p className="auth-p">Fill Out This Form To Sign Up</p>

                <Form
                  className="row g-3 signup-new-form"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                    isAdmin: false,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row
                    style={{ width: "100%", justifyContent: "center" }}
                    gutter={[16, 16]}
                  >
                    <Col lg={24} md={24} xs={24}>
                      <div className="wrapper-group-1000001858">
                        <div className="">
                          <Upload
                            name="image"
                            showUploadList={false}
                            style={{ position: "relative" }}
                            onChange={(e) => {
                              handleChangePro(e, "image");
                            }}
                            beforeUpload={beforeUpload}
                          >
                            {" "}
                            <div
                              style={{
                                width: "150px",
                                height: "150px",
                                border: "1px solid gray",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                borderRadius: "100%",
                                margin: "0 auto",
                              }}
                            >
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  alt="avatar"
                                  style={{
                                    width: "146px",
                                    height: "146px",
                                    objectPosition: "center",
                                    objectFit: "cover",
                                    borderRadius: "100%",
                                  }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </div>{" "}
                          </Upload>
                        </div>
                      </div>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="First Name On ID"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter First Name"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Last Name On ID"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Enter Last Name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Email Address"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Preferred Name"
                        name="preferredName"
                        rules={[
                          {
                            required: true,
                            message: "Enter Preferred Name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Preferred Name*"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Date of birth"
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message: "Enter Date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            required: true,
                            message: "Please enter your email address!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="James.Anderson@gmail.com"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Confirm Email"
                        name="confirmEmail"
                        rules={[
                          {
                            required: true,
                            message: "Enter Confirm Email*",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("email") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two emails do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Confirm Email*"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Zip Code"
                        name="zipCode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Zip Code",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Zip Code"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item label="Gender*" name="gender">
                        <Select placeholder="Select">
                          <Select.Option value="MALE">Male</Select.Option>
                          <Select.Option value="FEMALE">Female</Select.Option>
                          <Select.Option value="OTHERS">Others</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Phone Number"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Phone Number"
                          className="web-input"
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Emergency Contact Number"
                        name="emergencyContact"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Emergency Contact Number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Emergency Contact Number"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item label="Country" name="country">
                        <Space wrap className="filter-select">
                          <Select
                            size="large"
                            defaultValue="Select"
                            showSearch
                            placeholder="Country"
                            optionFilterProp="children"
                            onChange={(val, e) => {
                              handleChange("Country", val, e);
                            }}
                            filterOption={(input, option) =>
                              option.value
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countries?.map((country) => ({
                              value: JSON.stringify({
                                name: country.name,
                                isoCode: country.isoCode,
                              }),
                              label: country?.name,
                            }))}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Home DZ/FBO"
                        name="homeDZFBO"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Home DZ/FBO",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Home DZ/FBO"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Address Line 1"
                        name="streetAddressOne"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Address Line 1!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Address Line 1"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Address Line 2"
                        name="streetAddressTwo"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Address Line 2!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Address Line 2"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item label="state">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="Select"
                            onChange={(val, e) => {
                              handleChange("State", val, e);
                            }}
                            value={stateName}
                            style={{ width: "100%" }}
                            rules={[{ required: false }]}
                          >
                            {states.map((stateObj) => (
                              <Select.Option
                                key={stateObj?.isoCode}
                                value={stateObj?.name}
                              >
                                {stateObj?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item label="city">
                        <Space wrap className="filter-select">
                          <Select
                            defaultValue="Select"
                            onChange={(val, e) => {
                              handleChange("City", val, e);
                            }}
                            value={cityName}
                            style={{ width: "100%" }}
                            rules={[{ required: false }]}
                          >
                            {cities.map((cityObj) => (
                              <Select.Option
                                key={cityObj?.isoCode}
                                value={cityObj?.name}
                              >
                                {cityObj?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Space>
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Enter Password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Upload ID Card Pictures (front)"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <>
                          <Upload
                            name="idCardFront"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={beforeUpload}
                            onChange={(e) => {
                              handleChangePro(e, "govIdFront");
                            }}
                          >
                            {idCardFrontUrl ? (
                              <img
                                src={idCardFrontUrl}
                                alt="avatar"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "280px",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Upload ID Card Pictures (backside)"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <>
                          <Upload
                            name="idCardBack"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={beforeUpload}
                            onChange={(e) => {
                              handleChangePro(e, "govIdBack");
                            }}
                          >
                            {idCardBackUrl ? (
                              <img
                                src={idCardBackUrl}
                                alt="avatar"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "280px",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Upload skydiving license front"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <>
                          <Upload
                            name="licensefront"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={beforeUpload}
                            onChange={(e) => {
                              handleChangePro(e, "licenseFront");
                            }}
                          >
                            {licensefrontUrl ? (
                              <img
                                src={licensefrontUrl}
                                alt="avatar"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "280px",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                      </Form.Item>
                    </Col>
                    {/* <Col lg={8} md={12} xs={24}>
                      <Form.Item
                        label="Skydiving USPA #"
                        name="uspaNo"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Skydiving USPA #",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Skydiving USPA #"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>  */}
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Pilot license"
                        name="pilotLicense"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Pilot license",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Pilot license"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Upload pilot License card"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <>
                          <Upload
                            name="pilotLicense"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={beforeUpload}
                            onChange={(e) => {
                              handleChangePro(e, "licenseCard");
                            }}
                          >
                            {licenseCardUrl ? (
                              <img
                                src={licenseCardUrl}
                                alt="avatar"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "280px",
                                }}
                              />
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </>
                      </Form.Item>
                    </Col>
                    <Col lg={24}>
                      <Form.Item
                        name="terms"
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox>
                          I agree to the Terms and Conditions of Skydive BNB
                          LLC.
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {/* <Col lg={24} className="signup-with-buttons">
                      <div className="g-btn">
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={fbicn}
                            className=""
                          />
                          Signup with Facebook
                        </a>
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={gogoleicn}
                            className=""
                          />
                          Signup with Google
                        </a>
                      </div>
                    </Col> */}
                    <div className="" style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        htmlType="submit"
                        className="btn web-btn px-5"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {loading ? "Loading..." : "Sign Up"}
                      </Button>

                      <div
                        className="already-account-text"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        Already Have An Account?{" "}
                        <span onClick={() => navigate("/login")}>Login</span>{" "}
                      </div>
                    </div>
                  </Row>
                </Form>

                {/* <Form
                  className="row g-3"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                    isAdmin: false,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row
                    style={{ width: "100%", justifyContent: "center" }}
                    gutter={[16, 16]}
                  >
                    <Col lg={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter First Name"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Enter Last Name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Email Address"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            required: true,
                            message: "Please enter your email address!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="James.Anderson@gmail.com"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Phone Number"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Phone Number"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item label="Type" name="type">
                        <Select placeholder="Select">
                          <Select.Option value="USPA/LICENSE">
                            USPA Members/ License Jumpers
                          </Select.Option>
                          <Select.Option value="STUDENTS">
                            Tandem Students
                          </Select.Option>
                          <Select.Option value="CENTERS">
                            Skydiving Centers
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item label="Pilot Number" name="pilotNo">
                        <Input
                          size="large"
                          placeholder="Enter Pilot Number"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Enter Password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={24} className="signup-with-buttons">
                      <div className="g-btn">
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={fbicn}
                            className=""
                          />
                          Signup with Facebook
                        </a>
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={gogoleicn}
                            className=""
                          />
                          Signup with Google
                        </a>
                      </div>
                    </Col>
                    <div className="" style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        htmlType="submit"
                        className="btn web-btn px-5"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {loading ? "Loading..." : "Sign Up"}
                      </Button>

                      <div
                        className="already-account-text"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        Already Have An Account?{" "}
                        <span onClick={() => navigate("/login")}>Login</span>{" "}
                      </div>
                    </div>
                  </Row>
                </Form> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default CreateAccount;
